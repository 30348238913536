import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.footer`
    width: 100%;
    bottom: 0;
    color: white;
    background: ${({ theme }) => theme.color.secondColor};
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-around;
    align-items: center;
    padding: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: flex;
        flex-direction: column;
        padding: 40px 10px ;
        min-height: 80px;
        margin: 0;
        justify-content: center;
        align-items: center;
        gap: 20px;
    };
`;


export const Socials = styled.div`
    display: grid;
    justify-content: center;
    gap: 16px;
`;


export const InformationWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid white;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    gap: 12px;
`;

export const InforamtionHeader = styled.span`
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const InformationText = styled.span`
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 14px;
    };
`;

export const InformationLink = styled.a`
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: bold;
    margin-left: 12px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        opacity: 0.8;
    }
`;

export const Copyright = styled.h2`
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px 0;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 14px;
    };
`;

export const Logo = styled(Link)`
    font-size: 40px;
    text-decoration: none;
    color: white;
    letter-spacing: 6px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 20px;
        text-align: center;
    };
`;

export const LogoSpan = styled.p`
    color: ${({ theme }) => theme.color.fontColor} ;
    margin: 0;
    font-size: 28px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const InformationsWrapper = styled.div`
    display: grid;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

export const Informations = styled.p`
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 14px;
        text-align: center;
    };
`;

export const InformationsTitle = styled.h3`
    font-size: 28px;
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: normal;
    margin:0;
    text-align: center;


    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
        text-align: center;
    };
`;

export const SVGLinks = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        margin: 0;
        padding: 0;
    };
`;

export const SVGLink = styled.a`
    transition: 1s;
    width: 32px;
    height: 32px;
    fill: white;
    transition: 0.3s;

    &:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
`;

export const Image = styled.img`
    width: 48px;
    height: 48px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        width: 32px;
        height: 32px;
    };
`;


