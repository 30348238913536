import { Link, } from "react-router-dom";
import styled, { css } from "styled-components";

export const Wrapper = styled.section`
    padding: 80px 40px;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        padding: 20px;
    };
`;

export const Container = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    gap: 80px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        gap: 40px;
    };
`;

export const ImageSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBP}px){
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    };
`;

export const Image = styled.img`
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 20px 20px 10px ${({ theme }) => theme.color.fontThird};

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        max-width: 300px;
    };
`;

export const TextWrapper = styled.div`
    display: grid;
    justify-content: center;
`;

export const TextTitle = styled.h2`
    font-size: 52px;
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: normal;
    letter-spacing: 4px;
    text-align: center;
    margin: 0 0 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const Text = styled.p`
    text-align: justify;
    font-size: 24px;
    margin: 0 0 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 18px;
        margin: 0 0 20px;
    };
`;

export const ContactLink = styled(Link)`
    background: ${({ theme }) => theme.color.fontColor};
    color: white;
    text-decoration: none;
    padding: 12px 40px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    transition: 0.3s;
    margin: 0 auto;
    

    &:hover {
        opacity: 0.9;
        transform: scale(1.02);
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
    };
`;


export const Tile = styled.div`
    background: ${({ theme }) => theme.color.thirdColor};
    width: 100%;
    padding: 40px;
    border-radius: 20px;
    filter: drop-shadow(20px 20px 4px #d4d4d4);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        grid-template-columns: 1fr;
        gap: 20px;
    };
`;

export const TileSection = styled.div < { middle?: boolean }>`
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        border-bottom: 1px solid ${({ theme }) => theme.color.fontColor};    
        padding: 0 0 10px;
    };

    ${({ middle }) => middle && css`
        border-left: 2px solid ${({ theme }) => theme.color.fontColor};
        border-right: 2px solid ${({ theme }) => theme.color.fontColor};

        @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
            border-left: none;
            border-right: none;
        };
    `};
`;

export const SectionTitle = styled.p`
    text-align: center;
    margin: 0;
    font-size: 36px;
    color: ${({ theme }) => theme.color.fontColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 24px;
    };
`;

export const SectionText = styled.p`
    text-align: center;
    margin: 0;
    color: ${({ theme }) => theme.color.fontSecond};
    font-size: 24px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 16px;
    };
`;