import styled from "styled-components";
import { Link } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import bg from '../../../../common/Images/home/servicesBG.jpg';

export const Wrapper = styled.section`
    display: grid;
`;

export const TopWave = styled.img`
    margin-top: -120px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        margin-top: 0;
    };
`;

export const BottomWave = styled.img`
    rotate: 180deg;
    margin-top: -20px;
    z-index: -1;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        margin-top: 0;
    };
`;

export const Container = styled.div`
   background-image: url(${bg});
   background-position: center;
   background-size: cover;
   box-shadow:  inset 0px -20px 900px 233px ${({ theme }) => theme.color.secondColor};
`;

export const Content = styled.div`
    padding: 0px 80px;
    display: flex;
    max-width: 1600px;
    margin: -40px  auto;
    align-items: center;
    justify-content: center;
    gap:80px;

    
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: grid;
        padding: 0 20px;
        margin: 0 auto;
        gap: 20px;
    };
`;

export const ContentTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
`;

export const ContentTitle = styled.h3`
    font-size: 52px;
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: normal;
    letter-spacing: 4px;
    text-align: center;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const ContentText = styled.p`
    color: ${({ theme }) => theme.color.mainColor};
    font-weight: normal;
    font-size: 24px;
    text-align: justify;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 18px;
        margin: 0;
    };
`;

export const List = styled.ul`
    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        padding: 0 0 0 12px;
    };
`;

export const LIstTitle = styled.p`
    font-size: 28px;
    font-weight: bold;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 18px;
        padding: 0;
    };
`;

export const ListItem = styled.li`
    font-size: 20px;
    margin: 10px 0 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 16px;
    };
`;


export const ButtonLink = styled(Link)`
    background: ${({ theme }) => theme.color.fontColor};
    color: white;
    text-decoration: none;
    padding: 12px 40px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    transition: 0.3s;
    margin: 0 auto;
    

    &:hover {
        opacity: 0.9;
        transform: scale(1.02);
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
    };
`;

export const CustomSwiper = styled(Swiper)`
  max-width: 600px;
  position: relative;
  margin: auto;

    .swiper-button-prev {
        color: white;
    };

    .swiper-button-next {
        color: white;
    };

    .swiper-pagination-bullet-active {
        background: white;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        max-width: 280px;
    };
`;

export const CustomSlide = styled(SwiperSlide)`
    background-position: center;
    background-size: cover;
    border-radius: 20px;

`;

export const SwiperText = styled.p`
    position: fixed;
    text-align: center;
    top: 60%;
    background: rgba(217, 124, 39, 0.5);
    width: 100%;
    padding: 20px 0;
    font-size: 24px;
    font-weight: 600;
    color: white;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
        padding: 12px 0;
    };
`;

export const SwiperImage = styled.img`
    display: block;
    width: 100%;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.3));
`;