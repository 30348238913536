import React from 'react';
import { Article, ButtonLink, ButtonText, ButtonWrapper, SubTitle, TextWrapper, Title, Wrapper } from './styledHeader';
import { motion } from 'framer-motion';

export const Header = () => {
    return (
        <Wrapper>
            <TextWrapper>
                <motion.div
                    initial={{ y: '-50%', opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                >
                    <Title>Mirosław Stach</Title>
                    <SubTitle>Firma Usługowa</SubTitle>
                </motion.div>
                <Article
                    as={motion.p}
                    initial={{ x: '-40%', opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.7, delay: 0.2 }}
                >
                    Mirosław Stach Firma Usługowa to lider w branży robot ziemnych, oferujący usługi na najwyższym poziomie. Dysponujemy nowoczesnym sprzętem budowlanym, w tym koparkami, ładowarkami, wozidłami oraz wieloma innymi urządzeniami. Dzięki doświadczeniu i współpracy z licznymi firmami na terenie całej Polski, gwarantujemy solidność i terminowość realizacji każdego zlecenia.
                </Article>

                <ButtonWrapper
                    as={motion.div}
                    initial={{ y: '50%', opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.9, delay: 0.4 }}
                >
                    <ButtonText>Dowiedz się więcej!</ButtonText>
                    <ButtonLink to='/Usługi'>Nasze usługi</ButtonLink>
                </ButtonWrapper>
            </TextWrapper>
        </Wrapper>
    );
};

