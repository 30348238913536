import digger1 from '../common/Images/thumb/20230325_153138.jpg';
import digger2 from '../common/Images/thumb/20220706_150017.jpg';
import tractor from '../common/Images/thumb/20220908_175902.jpg';
import dumper from '../common/Images/thumb/20221222_091528.jpg';
import loader from '../common/Images/thumb/20220314_175517.jpg';
import phone from '../common/Images/FooterSVG/phone.svg';
import mail from '../common/Images/FooterSVG/mail.svg';
import home from '../common/Images/FooterSVG/home.svg';

export const links = [
    { text: 'Strona Główna', link: '/Strona-Główna' },
    { text: 'Usługi', link: '/Usługi' },
    { text: 'Galeria', link: '/Galeria' },
    { text: 'Kontakt', link: '/Kontakt' },
];

export const aboutData = [
    { count: '8 + ', text: 'Lat doświadczenia' },
    { count: '100 + ', text: 'Zadowolonych klientów' },
    { count: '20 + ', text: 'Maszyn budowlanych' },
];

export const homeList = [
    "Doświadczenie i Profesjonalizm: Nasz zespół składa się z doświadczonych specjalistów, którzy realizują zlecenia terminowo i rzetelnie.",
    "Nowoczesny Sprzęt: Dysponujemy najnowocześniejszym sprzętem, który pozwala nam wykonywać prace ziemne na najwyższym poziomie.",
    "Konkurencyjne Ceny: Oferujemy atrakcyjne ceny, które idą w parze z wysoką jakością usług.",
    "Zaufanie Klientów: Zdobyliśmy zaufanie wielu klientów dzięki naszej fachowej obsłudze i elastycznemu podejściu do każdego zlecenia."
];

export const homeServices = [
    { img: digger1, text: 'Usługi koparką gąsienicową' },
    { img: digger2, text: 'Usługi koparką kołową' },
    { img: tractor, text: 'Usługi ciągnikiem rolniczym' },
    { img: dumper, text: 'Wynajem wozideł budowlanych' },
    { img: loader, text: 'Wynajem ładowarek 8,5 - 25 ton' },
];
export const servicesData = [
    {
        photo: digger1,
        title: "Usługi koparką gąsienicową",
        description: `Oferujemy kompleksowe usługi koparką gąsienicową dla klientów prywatnych, firm budowlanych, deweloperów oraz inwestorów.
                      Nasze koparki to maszyna o wysokiej wydajności, pozwalająca na wykonanie nawet najtrudniejszych zadań związanych z pracami ziemnymi,
                      jak wykopy fundamentów, wykopy pod baseny, przepusty, wyburzenia, czy prace związane z budową dróg i parkingów.
                      Zapewniamy elastyczność w podejściu do potrzeb naszych klientów, dlatego oferujemy konkurencyjne ceny oraz dostosowanie usług do indywidualnych potrzeb.
                      Nasze usługi koparką gąsienicową są realizowane terminowo i z najwyższą starannością, co przekłada się na zadowolenie naszych klientów.
                      Skontaktuj się z nami, aby uzyskać więcej informacji na temat naszych usług.`,
        reverse: false,
    },
    {
        photo: digger2,
        title: "Usługi koparką kołową",
        description: `Oferujemy usługi koparką kołową o masie 18 ton. Nasz sprzęt jest nowoczesny i wydajny, co pozwala nam na wykonanie różnorodnych zadań związanych z pracami ziemnymi.
                      Dzięki koparce kołowej możemy wykonywać prace takie jak korytowanie, niwelacja terenu, wykopy pod fundamenty, podziemne instalacje, kanalizację,
                      a także prace związane z budową dróg i placów manewrowych.
                      Działamy szybko i sprawnie, zawsze z myślą o jak najlepszych efektach i satysfakcji klienta. Zapraszamy do skorzystania z
                      naszych usług i do kontaktu z nami w celu uzyskania szczegółowych informacji.
                      W naszej ofercie posiadamy rowniez młot wyburzeniowy, co pozwala nam na jeszcze większą elastyczność w wykonywaniu prac związanych z wyburzeniami, burzeniem ścian, fundamentów, a także przygotowaniem terenu pod budowę.`,
        reverse: true,
    },
    {
        photo: tractor,
        title: "Usługi ciągnikiem rolniczym",
        description: `Oferujemy usługi ciągnikiem rolniczym. Nasza firma dysponuje nowoczesnym ciągnikiem z przyczepą o pojemności 10 metrów sześciennych oraz beczką o
                      pojemności 10 000 litrów. Dzięki temu dysponujemy możliwością przewozu różnorodnych materiałów. W ramach naszych usług oferujemy między innymi polewanie dróg lub stabilizacji.
                      Oprócz transportu beczką, w naszej ofercie znajdują się usługi mulczerem leśnym które wykonywane są przez naszą specjalistyczną maszynę.
                      Dzięki niej jesteśmy w stanie szybko i skutecznie usuwać zadrzewienia oraz elementy roślinne w lesie, parkach czy przydrożach.
                      Dodatkowo w naszej ofercie znajdują się usługi zamiatarką uliczną.
                      Staramy się zawsze spełniać oczekiwania naszych klientów i zapewnić im wysoką jakość usług.`,
        reverse: false,
    },
    {
        photo: dumper,
        title: "Wynajem wozideł budowlanych",
        description: `Oferujemy wynajem wozideł budowlanych o różnych nośnościach od 1,5 tony do 16 ton z doświadczonymi operatorami lub bez.
                      Zachęcamy do kontaktu w celu uzyskania szczegółowych informacji.`,
        reverse: true,
    },
    {
        photo: loader,
        title: "Wynajem ładowarek 8.5 - 25 ton",
        description: `Oferujemy wynajem ładowarek o masie od 8.5 do 25 ton, w tym ładowarek kołowych.
                      Maszyny są regularnie serwisowane i przeglądane, a nasze doświadczenie i wysoka jakość zapewniają niezawodność i bezpieczeństwo.
                      Skontaktuj się z nami, aby uzyskać więcej informacji.`,
        reverse: false,
    }
];

export const ContactData = [
    { img: phone, title: "Telefon", text: '794-742-709', link: 'tel:794742706' },
    { img: mail, title: 'E-Mail', text: "miroslawstach88@gmail.com", link: 'mailto: miroslawstach88@gmail.com' },
    { img: home, title: 'Adres', text: '32-049 Przeginia 87', link: 'https://www.google.com/maps/place/Przeginia+87,+32-049,+Polska/data=!4m2!3m1!1s0x4716fbed46e8553f:0x38a385ba1d45c5?sa=X&ved=2ahUKEwiy3ZLp-7mBAxVtg_0HHSAXDMcQ8gF6BAgVEAA&ved=2ahUKEwiy3ZLp-7mBAxVtg_0HHSAXDMcQ8gF6BAgaEAI' }
]
