import { Container, InformationLink, InformationText, InformationWrapper, Informations, InformationsTitle, InformationsWrapper, SVGLink, Image, Logo, LogoSpan, SVGLinks, Socials, Wrapper } from "./styledFooter"
import phone from '../Images/FooterSVG/phone.svg';
import mail from '../Images/FooterSVG/mail.svg';
import home from '../Images/FooterSVG/home.svg';


export const Footer = () => {
    return (
        <Wrapper>
            <Container>
                <Logo to="/home" onClick={() => window.scrollTo(0, 0)}> Mirosław Stach<LogoSpan> Firma Usługowa</LogoSpan></Logo>
                <InformationsWrapper>
                    <InformationsTitle>Informacje</InformationsTitle>
                    <Informations>Mirosław Stach Firma Usługowa </Informations>
                    <Informations>Przeginia 87</Informations>
                    <Informations>Woj. Małopolskie </Informations>
                    <Informations>32-049 Przeginia </Informations>
                    <Informations>Nip:5130134954 </Informations>
                </InformationsWrapper>

                <Socials>
                    <InformationsTitle>Skontaktuj się z nami</InformationsTitle>
                    <SVGLinks>
                        <SVGLink target='blank' href='https://www.google.com/maps/place/Przeginia+87,+32-049,+Polska/data=!4m2!3m1!1s0x4716fbed46e8553f:0x38a385ba1d45c5?sa=X&ved=2ahUKEwiy3ZLp-7mBAxVtg_0HHSAXDMcQ8gF6BAgVEAA&ved=2ahUKEwiy3ZLp-7mBAxVtg_0HHSAXDMcQ8gF6BAgaEAI'><Image src={home} /></SVGLink>
                        <SVGLink href="mailto: miroslawstach88@gmail.com"><Image src={mail} /></SVGLink>
                        <SVGLink href="tel:794742706"><Image src={phone} /></SVGLink>
                    </SVGLinks>
                </Socials>
            </Container>
            <InformationWrapper>
                <InformationText>
                    ©2024 Projekt i realizacja <InformationLink href="https://www.facebook.com/marcin.izdebski.7528/" target="blank">Marcin Izdebski</InformationLink>
                </InformationText>
            </InformationWrapper>
        </Wrapper>
    )
}