import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import header from '../../common/Images/fullScreen/20220516_114202.jpg';

export const Wrapper = styled.section`
    padding: 20px 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 20px 10px;
        display: grid;
        justify-content: center;
        padding: 0 20px;
    };
`;

export const Header = styled.header`
    background-image: url(${header});
    box-shadow:  inset 0px -20px 500px 120px ${({ theme }) => theme.color.secondColor};
    background-position: center;
    border-radius: 20px;
    background-size: cover;
    min-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        min-height: 80px;
        box-shadow:  inset 0px -20px 500px 10px ${({ theme }) => theme.color.secondColor};
    };
`;

export const Title = styled.h4`
    font-size: 86px;
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: normal;
    letter-spacing: 4px;
    text-align: center;
    margin: 0;
    text-shadow: ${({ theme }) => theme.color.mainColor} 1px 0 0px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const GalleryWrapper = styled.div`
    column-count: 4;
    max-width: 1800px;
    margin: 0 auto;
    padding: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBP}px){
        column-count: 3;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        column-count: 1;
        padding: 0 12px;
    };
`;

export const GalleryLink = styled(Link)`
    margin: 0 auto;
`;

export const Button = styled.button`
    background: ${({ theme }) => theme.color.fontColor};
    color: white;
    text-decoration: none;
    padding: 12px 40px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    transition: 0.3s;
    margin: 40px 0;
    border: none;
    cursor: pointer;
    float:right;


    &:hover {
        opacity: 0.9;
        transform: scale(1.02);
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
    };
`;

export const Pics = styled.div`
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0 0 10px rgb(148, 152, 158);
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        margin-top: 20px;
    };
`;

export const Image = styled.img`
    width: 100%;
    cursor: pointer;
    transition: 0.5s linear;
    transition-timing-function:ease-in-out;
    transform: scale(1.04);

    &:hover {
        zoom: normal;
        transform: scale(1.1);
        opacity: 70%;
    };
`;

export const FullScreenWrapper = styled.div`
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    padding: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 12px;
    };
`;

export const CustomSwiper = styled(Swiper)`
    width: 100%;
    height: 100%;

    .swiper-button-prev {
        color: ${({ theme }) => theme.color.fontColor};
    };

    .swiper-button-next {
        color: ${({ theme }) => theme.color.fontColor};
    };
`;

export const CustomSlide = styled(SwiperSlide)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
`;

export const FullScreenImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    user-select: none;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        margin-top: 25%;
    };
`;

export const CloseButton = styled.button`
    position: fixed;
    z-index: 1;
    top: 20px;
    color: ${({ theme }) => theme.color.mainColor};
    transition: 0.3s;
    cursor: pointer;
    right: 80px;
    background: transparent;
    border: none;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        right: 10px;
    };

    &:hover {
        transform: scale(1.2);

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        transform: none;
    };
}`;
