import { styled } from "styled-components";
import bg from '../../common/Images/fullScreen/20230331_155159.jpg';

export const Wrapper = styled.section`
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    box-shadow:  inset 0px -20px 1200px 383px ${({ theme }) => theme.color.mainColor};
    margin: 0 auto;
    padding: 40px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        padding: 10px;
    };
`;

export const TitleWrapper = styled.div`

`;

export const Title = styled.h1`
    text-align: center;
    font-size: 64px;
    color: ${({ theme }) => theme.color.fontColor};
    margin-bottom: 0px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const Span = styled.h2`
    text-align: center;
    font-size: 48px;
    margin: 10px;
    font-weight: normal;
    color: ${({ theme }) => theme.color.fontSecond};

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 24px;
    };
`;


export const TilesWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    max-width: 1500px;
    margin: auto;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    gap: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBP}px){
        flex-direction: column;
        margin: 40px 0;
    };
`;

export const Tile = styled.a`
    background: ${({ theme }) => theme.color.secondColor};
    padding: 40px;
    text-decoration: none;
    color: ${({ theme }) => theme.color.mainColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 320px;
    min-height: 320px;
    border-radius: 20px;
    border: 4px solid ${({ theme }) => theme.color.fontColor};
    filter: drop-shadow(20px 20px 8px #b3b3b3);
    transition: 0.3s;

    &:hover {
        transform: scale(1.02);
        opacity: 0.95;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        min-height: 200px;
        min-width: 240px;
        padding: 14px;
  };
`;

export const TileSVG = styled.img`
    width: 120px;
    height: 120px;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        width: 60px;
        height: 60px;
    };
`;

export const TileTitle = styled.span`
    font-size: 36px;
    color: ${({ theme }) => theme.color.fontColor};


    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 24px;
    };
`;

export const TileLink = styled.span`
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 16px;
    };
`;
