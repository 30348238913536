import { css, styled } from "styled-components";
import header from '../../common/Images/fullScreen/20230303_091050.jpg';

export const Wrapper = styled.section`
    padding: 20px 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 20px 10px;
        display: grid;
        justify-content: center;
        padding: 0 20px;
    };
`;

export const Header = styled.header`
    background-image: url(${header});
    box-shadow:  inset 0px -20px 500px 120px ${({ theme }) => theme.color.secondColor};
    background-position: center;
    border-radius: 20px;
    background-size: cover;
    min-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        min-height: 80px;
        box-shadow:  inset 0px -20px 500px 10px ${({ theme }) => theme.color.secondColor};
    };
`;

export const Title = styled.h4`
    font-size: 86px;
    color: ${({ theme }) => theme.color.fontColor};
    font-weight: normal;
    letter-spacing: 4px;
    text-align: center;
    margin: 0;
    text-shadow: ${({ theme }) => theme.color.mainColor} 1px 0 0px;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const TilesWrapper = styled.article`
    max-width: 1600px;
    margin: 0 auto;
    display: grid;
    gap: 40px;
    padding: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px) {
        grid-gap: 20px;
        padding: 10px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px) {
        grid-template-columns: 1fr;
    };
`;

export const Tile = styled.div <{ reverse?: boolean }>`
    display: flex;
    background: ${({ theme }) => theme.color.secondColor};
    gap: 20px;
    justify-content: start;
    align-items: center;
    margin: 0 auto;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 20px ${({ theme }) => theme.color.fontThird});
    border: 2px solid ${({ theme }) => theme.color.secondColor};

    ${({ reverse }) => reverse && css`
        flex-direction: row-reverse;
    `};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        flex-direction: column;
        gap: 12px;
    };
`;

export const Photo = styled.img<{ reverse?: boolean }>`
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px 0 0 20px;

    ${({ reverse }) => reverse && css`
        flex-direction: row-reverse;
        border-radius: 0px 20px 20px 0px;
    `};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
            border-radius: 20px 20px 0 0;
    };
`;

export const TextArea = styled.div`
    color: ${({ theme }) => theme.color.mainColor};
    font-size: 18px;
    max-width: 720px;
    text-align: justify;
    margin: 0;
    padding: 30px 30px 20px 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px) {
        font-size: 12px;
        padding: 12px
    };
`;

export const TileTitle = styled.p`
    padding-bottom: 20px;
    font-size: 32px;
    color: ${({ theme }) => theme.color.fontColor};
    text-align: center;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBP}px){
        font-size: 20px;
        padding-bottom: 12px;
    };
`;