import React from 'react';
import { Span, Tile, TileLink, TileSVG, TileTitle, TilesWrapper, Title, TitleWrapper, Wrapper } from './styledContact';
import { ContactData } from '../../core/arrays';
import { motion } from 'framer-motion';

export const Contact = () => {
    return (
        <Wrapper>
            <TitleWrapper
                as={motion.div}
                initial={{ y: '10%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
            >
                <Title>Masz Pytania?</Title>
                <Span>Skontaktuj się z nami wybierając jeden z poniższych linków</Span>
            </TitleWrapper>

            <TilesWrapper
                as={motion.div}
                initial={{ y: '30%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7, }}
            >
                {ContactData.map((contact, index) => (
                    <Tile key={contact.title} href={contact.link} target='_blank'>
                        <TileSVG src={contact.img} alt='Tile' />
                        <TileTitle>{contact.title}</TileTitle>
                        <TileLink>{contact.text}</TileLink>
                    </Tile>
                ))}
            </TilesWrapper>
        </Wrapper>
    );
};

