import React from 'react';
import { TextArea, Photo, Header, Tile, TilesWrapper, Title, Wrapper, TileTitle } from './styledServices';
import { servicesData } from '../../core/arrays';
import { motion } from 'framer-motion';

export const Services = () => {
    return (
        <Wrapper>
            <Header
                as={motion.header}
                initial={{ y: '10%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}>
                <Title>Nasze usługi</Title>
            </Header>

            <TilesWrapper>
                {servicesData.map((service, index) => (
                    <Tile
                        key={index}
                        reverse={service.reverse}
                        as={motion.div}
                        initial={{ x: '-30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <Photo reverse={service.reverse} src={service.photo} />
                        <TextArea>
                            <TileTitle>{service.title}</TileTitle>
                            {service.description}
                        </TextArea>
                    </Tile>
                ))}
            </TilesWrapper>
        </Wrapper>
    );
};