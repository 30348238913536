import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../features/Home/home";
import { Navbar } from "../common/Navbar/navbar";
import { Footer } from "../common/Footer/footer";
import { Contact } from "../features/Contact/contact";
import { Gallery } from "../features/Gallery/gallery";
import { Services } from "../features/Services/services";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/Strona-Główna" element={<Home />} />
        <Route path={"*"} element={<Navigate replace to="/Strona-Główna" />} />
        <Route path="/Kontakt" element={<Contact />} />
        <Route path="/Galeria" element={<Gallery />} />
        <Route path="/Usługi" element={<Services />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
