import React from 'react';
import { BottomWave, ButtonLink, Container, Content, ContentText, ContentTextWrapper, ContentTitle, CustomSlide, CustomSwiper, LIstTitle, List, ListItem, SwiperImage, SwiperText, TopWave, Wrapper } from './styledServices';
import topWave from '../../../../common/Images/home/homeSvgTop.svg';
import { homeList, homeServices, } from '../../../../core/arrays';
import { EffectCube, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { motion } from 'framer-motion';

export const Services = () => {
    return (
        <Wrapper>
            <TopWave src={topWave} alt='wave' />
            <Container>
                <Content>
                    <ContentTextWrapper
                        as={motion.div}
                        initial={{ x: '-30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <ContentTitle>Nasza oferta</ContentTitle>
                        <ContentText>
                            Firma Mirosław Stach od ponad 8 lat jest liderem w branży robót ziemnych w Polsce. Specjalizujemy się w wykopach, niwelacji terenu oraz innych pracach ziemnych, zapewniając najwyższą jakość usług.

                            <LIstTitle>Dlaczego warto wybrać naszą firmę?</LIstTitle>
                            <List>
                                {homeList.map((item) => (
                                    <ListItem>{item}</ListItem>
                                ))}

                            </List>
                        </ContentText>

                        <ButtonLink to="/Oferta">Czytaj więcej o naszej ofercie</ButtonLink>
                    </ContentTextWrapper>

                    <motion.div
                        initial={{ x: '30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <CustomSwiper<React.ComponentType<any>>
                            effect={'cube'}
                            grabCursor={true}
                            cubeEffect={{
                                shadow: true,
                                slideShadows: true,
                                shadowOffset: 20,
                                shadowScale: 0.94,
                            }}
                            autoplay={{
                                delay: 5000,
                            }}
                            pagination={true}
                            navigation
                            modules={[EffectCube, Pagination, Navigation, Autoplay]}
                        >
                            {homeServices.map((service) => (
                                <CustomSlide key={service.text}>
                                    <SwiperImage src={service.img} />
                                    <SwiperText>{service.text}</SwiperText>
                                </CustomSlide>
                            ))}
                        </CustomSwiper>
                    </motion.div>
                </Content>
            </Container>
            <BottomWave src={topWave} alt='wave' />

        </Wrapper>
    );
};
