import React from 'react';
import { Text, Container, ContactLink, Image, ImageSection, TextWrapper, Wrapper, Tile, TileSection, SectionTitle, SectionText, TextTitle } from './styledAbout';
import about from '../../../../common/Images/home/aboutImg.jpg';
import { aboutData } from '../../../../core/arrays';
import { motion } from 'framer-motion';
export const About = () => {
    return (
        <Wrapper>
            <Container>
                <ImageSection>
                    <Image
                        src={about}
                        alt='about'
                        as={motion.img}
                        initial={{ x: '-30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    />
                    <TextWrapper
                        as={motion.div}
                        initial={{ x: '30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <TextTitle>O Firmie</TextTitle>
                        <Text>
                            Firma Mirosław Stach to solidny partner w branży robot ziemnych na terenie całej Polski od ponad 8 lat. Specjalizujemy się w wykopach, niwelacji terenu i innych pracach ziemnych, gwarantując najwyższą jakość. Nasz doświadczony zespół i nowoczesny sprzęt zapewniają terminową i rzetelną realizację każdego zlecenia. Zaufanie klientów zdobyliśmy dzięki fachowej obsłudze i konkurencyjnym cenom. Zapraszamy do skorzystania z naszej oferty!
                        </Text>

                        <ContactLink to='/Kontakt'>
                            Skontaktuj się z nami
                        </ContactLink>
                    </TextWrapper>
                </ImageSection>

                <Tile
                    as={motion.div}
                    initial={{ y: '50%', opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.7 }}
                >
                    {aboutData.map((data, index) => (
                        <TileSection key={index} middle={index % 2 === 0}>
                            <SectionTitle>{data.count}</SectionTitle>
                            <SectionText>{data.text}</SectionText>
                        </TileSection>
                    ))}
                </Tile>
            </Container>
        </Wrapper>
    );
};
