export const theme = {
    color: {
        mainColor: "#fcfcfc",
        secondColor: "#313131",
        fontColor: "#d97c27",
        fontSecond: '#00000',
        fontThird: '#6b6b6a',
        thirdColor: '#f2f2f2',
    },

    breakPoint: {
        firstBreakPoint: 1,
        firstBP: 1400,
        secondBP: 1200,
        thirdBP: 1000,
        mobileMax: 800,
    }
};