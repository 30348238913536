import { styled } from "styled-components";
import bg from '../../../../common/Images/home/headerBG.png';
import mobileBg from '../../../../common/Images/home/mobileHeaderBG.jpg';
import { Link, } from "react-router-dom";

export const Wrapper = styled.header`
    min-height: 100vh;
    border-radius: 40px;
    max-width: 1800px;
    margin: 0 auto;
    background-image: url('${bg}');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: start;
    padding: 80px;
    position: relative;
    box-shadow:  inset 600px -105px 200px 123px rgba(255,255,255,1);
    display: grid;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        background-image: url('${mobileBg}');
        border-radius: 0;
        height: 80vh;
        box-shadow:  none;
        align-items: center;
        padding: 0 20px;
    };
`;

export const TextWrapper = styled.article`
    max-width: 640px;
    margin-top: 120px;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBP}px){
        max-width: 100%;
        margin-top: 0px;
        background: rgba(255, 255, 255, 0.9);
        padding: 20px;
    };
`;

export const Title = styled.h1`
    font-size: 80px;
    letter-spacing: 6px;
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.color.fontColor};
    margin: 0 0 20px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 28px;
    };
`;

export const SubTitle = styled.p`
    font-size: 40px;
    text-align: center;
    margin: 0;
    margin: 0 0 20px;
    color: ${({ theme }) => theme.color.fontSecond};

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 20px;
    };
`;

export const Article = styled.p`
    font-size: 20px;
    color: ${({ theme }) => theme.color.fontThird};
    text-align: justify;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
        padding-right: 0px;
    };
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ButtonText = styled.p`
    text-align: center;
    font-size: 24px;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 16px;
    };
`;

export const ButtonLink = styled(Link)`
    background: ${({ theme }) => theme.color.fontColor};
    color: white;
    text-decoration: none;
    padding: 12px 40px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    transition: 0.3s;
    margin: auto;

    &:hover {
        opacity: 0.9;
        transform: scale(1.02);
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.thirdBP}px){
        font-size: 14px;
    };
`;